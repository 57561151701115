import React from 'react';
import { NavLink } from 'react-router-dom';
import './Footer.css'
import About from './components/About'

function Footer(){
    
    return (
    <div className="Footer">
     <span>Strategia IT Podcast è un'iniziativa della</span>  
      <p>
       <a href="https://refacturing.com" target="_blank" rel="noopener noreferrer">Refacturing Ltd Company</a>       
      </p>
      <About />
      <ul className="mt-3 Footer__menu_items">
        <li>
          <NavLink to="/aziende">Aziende</NavLink>
        </li>
        <li>
          <NavLink to="/libri">Libri</NavLink>
        </li>        
      </ul>    
    </div>    
  )
   
}

export default Footer;