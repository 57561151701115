import React from 'react';
import './VideoCard.css';
import Player from './Player'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';


class VideoCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showComponent: false,
      showTitle:true,
    };
    this._onButtonClick = this._onButtonClick.bind(this);
  }
  _onButtonClick() {
    const test = !this.state.showTitle;
    console.log(test);
    this.setState({
      showComponent: true,
      showTitle:test
    
    });
  }
  render(){
    return (
    
      <div className="VideoCard">
  
      {this.state.showTitle && <div className="VideoCard__left" onClick={this._onButtonClick}>
        <PlayCircleOutlineIcon className="secondary" />
        <div>
         {this.props.title}</div>
        </div>
      }
        
        {this.state.showComponent ?
          <Player episodeId={this.props.episodeId}/> :
          null
       }  
      </div>
      
      
    )
  }
  
}

export default VideoCard;
