import React from 'react';
import YouTubeIcon from './icons/yt_icon_rgb.png';
import './Header.css'
import SpotifyIcon from './icons/Spotify_Icon_RGB_Green.png'
import AppleIcon from './icons/Apple_Podcast_Icon-3.png'
import TelegramIcon from './icons/Telegram_logo.png'

function Header() {
  const handleClick = event => {
    event.preventDefault();
    const element_to_scroll_to = document.getElementById('interviews');
    element_to_scroll_to.scrollIntoView();
  };
  
    return (
      <div className="header">
        <div className="header__left">
        
        <a href="https://it.refacturing.com/itunes" target="_blank" rel="noopener noreferrer"><img alt="Vota su IPodcast" className="header__Apple_icon" src={AppleIcon}/></a>
        
        <a  href="http://t.me/riccardomancinelli" target="_blank"  rel="noopener noreferrer"> 
        <img src ={TelegramIcon} alt="Scrivimi su Telegram" className='header__Telegram_icon'></img>
        
        
        </a>
                    
          <a  href="https://www.youtube.com/c/riccardomancinelli/?sub_confirmation=1" target="_blank" rel="noopener noreferrer"><img src={YouTubeIcon} alt="Segui il mio Canale YouTube" className="header__YouTube_icon"/></a>

          <a href="https://open.spotify.com/show/4KmEl7Phl6i5WoI28mNiyg" target="_blank" rel="noopener noreferrer"><img alt="Seguimi su Spotify" className="header__Spotify_icon" src={SpotifyIcon}/></a>
        </div>        
        <h1 className="title"><span className="title_payoff">Diamo Voce alla</span><span> Strategia IT</span></h1>
        <div className="header__interviews">
          <span><a href="http://t.me/riccardomancinelli" target="_blank" rel="noopener noreferrer">Suggerisci un'intervista</a></span>
          <span onClick={handleClick}>Interviste e Contributi</span>
        </div>      
      </div>    
    )
  
}

export default Header;