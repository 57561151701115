import React from 'react';
import './App.css';
import Header from './Header'
import Footer from './Footer'
import PodcastContainer from './PodcastContainer'
import Interviews from './Interviews';
import {HashRouter, Switch , Route} from 'react-router-dom';
import Companies from './components/Companies'
import Books from './components/Books'
import 'font-awesome/css/font-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <div className="App">
      <HashRouter>
      <Header/>
        <Switch>
        <Route path="/libri">
          <Books/>
        </Route>
        <Route path="/aziende">
          <Companies/>
        </Route>
        <Route path="/">
          <PodcastContainer/>
          <Interviews /> 
        </Route>
        
        </Switch>
        <Footer/>
      </HashRouter>      
    </div>
  );
}

export default App;
