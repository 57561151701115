import React from 'react'
import {NavLink} from 'react-router-dom'

function Books(){
  const books = [
    {id:1,name:'The Right Way to Select Technology',url:'https://amzn.to/2Il5hNG',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/01-GfsPej'},
    {id:2,name:'Traction',url:'https://amzn.to/2Il5hNG',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/traction'},
    {id:3,name:'Monetizing Innovation',url:'https://amzn.to/2n0zI2C',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/shock-da-funzionalita'},
    {id:4,name:'The Startup Canvas',url:'https://thestartupcanvas.com/it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/thestartupcanvas'},
    {id:5,name:'Overcrowded',url:'http://www.verganti.com/overcrowded/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/31-Y5G1mc'},
    {id:6,name:'Email Marketing Automation',url:'https://www.amazon.it/marketing-automation-accelera-rapporto-clienti/dp/8857907120?tag=techblast0f-20',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/32-nSg2Vh'},
    {id:7,name:'Lean presentation design',url:'https://www.amazon.it/presentation-design-presentazione-perfetta-docchio/dp/8891789763',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/maurizio-la-cava'},
    {id:8,name:'Startup Marketing',url:'https://www.alessiacamera.com/it/startup-marketing-libro-italiano/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/alessia-camera'},
    {id:9,name:'Extreme Contracts',url:'http://jacoporomei.com/extreme-contracts/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/negoziazione-risk-management'},
    {id:10,name:'Public Speaking for Geeks Succinctly',url:'https://www.syncfusion.com/ebooks/public_speaking_for_geeks_succinctly',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/public-speaking-for-geeks'},
    {id:11,name:'Pretotype it - Pretotipare',url:'https://www.amazon.co.uk/Pretotype-Pretotipare-Italian-Alberto-Savoia-ebook/dp/B00AZ0IVC4?tag=techblast0f-20',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/pretotyping'},
    {id:12,name:'User eXperience design',url:'http://bit.ly/botta-UX-design',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/user-experience-design'},
    {id:13,name:'Testing Business Ideas',url:'https://books.google.co.uk/books?id=AdO8DwAAQBAJ',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/testing-business-ideas'},
    {id:14,name:'Branded podcast',url:'https://www.archeostorie.it/branded-podcast-archeostorie',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/branded-podcast'},
    {id:15,name:'Sul sentiero Agile',url:'https://www.officinaagile.it/libro-sul-sentiero-agile/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/officinaagile'},
    {id:16,name:'Emotional CRM',url:'https://www.maura.it/imieilibri/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/emotional-crm'},
    {id:17,name:'Frameworkless Front-End Development',url:'https://www.amazon.it/Frameworkless-Front-End-Development-Dependencies-Controlling/dp/1484249666',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/flowing'},
  ]
  return(
    <div>
    <h3 className="pt-3">Libri</h3>
    <div className="container border p-3 w-100 mb-3 rounded">
    {
      books.reverse().map(company => {
        return (
          <div className="row m-2" key={company.id}>
            <div className="col-md-6 mx-auto">
              <a className="mr-3" href={company.url} target="_blank" rel="noopener noreferrer"
              title={`${company.name} Website`} alt='Website url'>{company.name}</a>
              <a href={company.episode_url} target="_blank" rel="noopener noreferrer" title='Podcast Episode' alt='Episode Podcast'><i className="fa fa-play-circle-o" aria-hidden="true"></i> </a>  
            </div>
            
          </div>
        )
      })
    }
    <NavLink to="/" className="text-secondary">Ritorna alla ricerca</NavLink>      
    </div>
    </div>
  )
}

export default Books;