import React from 'react'
import {NavLink} from 'react-router-dom'

function Companies(){
  const companies = [
    {id:1,name:'Grownectia',url:'https://www.grownnectia.com/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/thestartupcanvas'},
    {id:2,name:'Your Story',url:'https://digitalpr.pro/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/jessica-malfatto'},
    {id:3,name:'MLC Presentation Design',url:'https://www.mauriziolacava.com',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/maurizio-la-cava'},
    {id:4,name:'Kanji Lab',url:'https://www.kanjilab.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/chiara-magnani'},
    {id:5,name:'Warehouse Coworking Factory',url:'https://warehouse.marche.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/warehouse'},
    {id:6,name:'Your Inspiration',url:'https://yourinspiration.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/your-inspiration'},
    {id:7,name:'Evolve',url:'https://justevolve.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/evolve'},
    {id:8,name:'Strumenta',url:'https://strumenta.com//',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/language-engineering'},
    {id:9,name:'Sefirot',url:'https://www.sefirot.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/ux-cards'},
    {id:10,name:'Innoteam',url:'https://www.innoteam.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/cto'},
    {id:11,name:'Nethesis',url:'https://nethesis.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/community-manager'},
    {id:12,name:'Managed Designs',url:'https://www.manageddesigns.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/decisioni-tecniche'},
    {id:13,name:'Flowing',url:'https://www.flowing.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/flowing'},
    {id:14,name:'Airbag Studio',url:'https://www.airbagstudio.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/airbagstudio'},
    {id:15,name:'Belka',url:'https://belkadigital.com/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/belkadigital'},
    {id:16,name:'SparkFabrik',url:'https://www.sparkfabrik.com/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/sparkfabrik-su-strategiait'},
    {id:17,name:'Avanscoperta',url:'https://www.avanscoperta.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/avanscoperta'},
    {id:18,name:'Particular Software',url:'https://particular.net/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/condivisioneconoscenza'},
    {id:19,name:'Tangible',url:'https://tangible.is/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/tangible'},
    {id:20,name:'Quantyca',url:'https://quantyca.it/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/data-management'},
    {id:21,name:'Radicalbit',url:'https://radicalbit.io/',episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/fast-data-continuous-intelligence'},
    {id:22, name:'Voxnest', url:'https://voxnest.com', episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/sostenibilita-produttori-di-contenuto'},
    {id:23, name:'The Doers', url:'http://thedoers.co/', episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/mercati-infiniti'},
    {id:24, name:'Slope', url:'https://www.slope.it/', episode_url:'https://www.spreaker.com/user/riccardo.mancinelli/slope'}
  ]
  return (
    <div>
    <h3 className="pt-3">Aziende</h3>
    <div className="container border p-3 w-50 mb-3 rounded">
    {
      companies.reverse().map(company => {
        return (
          <div className="row m-2" key={company.id}>
            <div className="col-md-6 mx-auto">
              <a className="mr-3" href={company.url} target="_blank" rel="noopener noreferrer"
              title={`${company.name} Website`} alt='Website url'>{company.name}</a>
              <a href={company.episode_url} rel="noopener noreferrer" target="_blank" title='Podcast Episode' alt='Episode Podcast'><i className="fa fa-play-circle-o" aria-hidden="true"></i> </a>  
            </div>
            
          </div>
        )
      })
    }
    <NavLink to="/" className="text-secondary">Ritorna alla ricerca</NavLink>      
    </div>
    </div>
  )
}

export default Companies