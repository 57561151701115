import React,{useState} from 'react'
import {Button,Modal} from 'react-bootstrap'

function About(){

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return(
    <div>
      <Button variant="primary" onClick={handleShow}>
        About
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>About</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>
        Riflessioni e considerazioni per adottare una strategia IT(Information Technology) nell'ambito Web.
        <br/><br/>
        C’è chi è appassionato di tecnologia
        Chi di business
        Io a comprendere come la tecnologia permetta al business di funzionare
        </p>          
        <p>La principale differenza di fare Business Online rispetto a quello Offline è quella di costruire il proprio business su asset tecnologici e sfruttare interamente i modelli di business che meglio si combinano tra loro.
        </p>
        <p>
        Si dice prima la strategia poi la tecnologia.
        </p>
        <p>
        Esistono strategie di comunicazione, di marketing, di business,
        sembrerebbe esistano strategie in ogni ambito.
        </p>
        <p>
        La più sottovalutata è la strategia IT, quella strategia che rende possibile online tutte le altre.
        </p>
        <p>
        Capire la tecnologia ci aiuta a scegliere e capire come allineare la stessa ai bisogni del business.
        </p>
        <p>
        Se trascuri una sola componente, tra quelle fondamentali del tuo business, avrai impatti sui tuoi obiettivi.
        </p>
        <p>
        Ci preoccupiamo di tutti i nostri asset? Quello tecnologico, se non banalizzato, può fruttare più di quanto pensiamo.
        </p>
        <p>
        Vuoi fare Business Online? Segui Strategia IT!</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default About;