import React from 'react';
import useScript from './hooks/useScript';

function Player({episodeId}){

  useScript("https://widget.spreaker.com/widgets.js");
    return (
      <div className="Player">
      <a className="spreaker-player" href="https://www.spreaker.com" data-resource={"episode_id=" + episodeId} data-width="100%" data-height="200px"> </a>
      </div>
      
      
    )
}
export default Player;