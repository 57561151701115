import React from 'react';
import { useEffect } from 'react';
import './PodcastContainer.css'
import VideoCard from './VideoCard'
import episodes from './data/episodes.json'

function PodcastContainer(){
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searchResults, setSearchResults] = React.useState([]);
  
  const handleChange = event => {
    setSearchTerm(event.target.value);
  };
  React.useEffect(() => {
    const results = episodes.filter(episode =>
      episode.title.toLowerCase().includes(searchTerm.toLowerCase()) || episode.keywords.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }, [searchTerm]);
  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://widget.spreaker.com/widgets.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);
  return (
    
    <div className="Podcast-Container">
      <div className="Podcast-Container__search">
            <input type="text"  placeholder="Filtra"
            value={searchTerm}
            onChange={handleChange}
            />            
          </div>
      <div className="Podcast-Container__content">
      
      {searchResults.map( (episode) => {
        
        return (            
              <VideoCard key={episode.id} title={episode.title} episodeId={episode.id} image="" />            
            )
          }
        )
      }
      
      </div>   
      {searchResults.length === 0 ? <pre className="Podcast-Container__content_sorry">¯\_(ツ)_/¯</pre> : ''}
    </div>
  )
}

export default PodcastContainer;