import React from 'react';
import './Interviews.css';

function  Interviews(){
  return (

    <div className="Interviews">
      <h2 className="interviews-title" id="interviews">Interviste e Contributi</h2>
    
  <div className="Interview-Content">

    <div className="Interview-Content__item">
      <iframe width="80%" height="50%" src="https://www.youtube.com/embed/H_bA7mkNiX4" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="API Economy, come erogare e monetizzare software e dati"></iframe>
    </div>

    <div className="Interview-Content__item">
      <iframe width="80%" height="50%" src="https://www.youtube.com/embed/YMM0k3pOzKY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Podcast fantastici e dove ascoltarli - S02E01"></iframe>
    </div>

    <div className="Interview-Content__item">
      <iframe src="https://widget.spreaker.com/player?episode_id=41718315&theme=light&autoplay=false&playlist=false&cover_image_url=https%3A%2F%2Fd3wo5wojvuv7l.cloudfront.net%2Fimages.spreaker.com%2Foriginal%2F3de69d9fc5e9d8ba2dd4d5fd526fb764.jpg"
      title="Intervista" width="80%" height="50%"></iframe>
    </div>

    <div className="Interview-Content__item">
      <iframe src="https://widget.spreaker.com/player?episode_id=40717867&theme=light&autoplay=false&playlist=false&cover_image_url=https%3A%2F%2Fd3wo5wojvuv7l.cloudfront.net%2Fimages.spreaker.com%2Foriginal%2Fdc2bb63ba656e15aa37fc301d66a6ba2.jpg" width="80%" height="50%" title="Crea il tuo corso"></iframe>
    </div>
    
    <div className="Interview-Content__item">
      <iframe width="80%" height="50%" src="https://www.youtube.com/embed/LC1Nul3RQuQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Innoteam"></iframe>
    </div>  
    
    <div className="Interview-Content__item">
      <iframe src="https://widget.spreaker.com/player?episode_id=31842876&theme=light&autoplay=false&playlist=false&cover_image_url=https%3A%2F%2Fd3wo5wojvuv7l.cloudfront.net%2Fimages.spreaker.com%2Foriginal%2Fe60ad5ff5b8475515c943d495ab819c8.jpg" width="80%" height="50%" frameBorder="0" title="Che Vita da Marketer">
      </iframe>
    </div>
    
    <div className="Interview-Content__item">
      <iframe width="80%" height="50%" src="https://www.youtube.com/embed/4K5xFpNXwU0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="SMU"></iframe>
    </div>

    
    <div className="Interview-Content__item">
      <iframe src="https://widget.spreaker.com/player?episode_id=20705787&theme=light&autoplay=false&playlist=false&cover_image_url=https%3A%2F%2Fd3wo5wojvuv7l.cloudfront.net%2Fimages.spreaker.com%2Foriginal%2F5c9cd0c5d4f3c5a663a08e7d57e6aa63.jpg" width="80%" height="50%" frameBorder="0" title="Pardes"></iframe>
    </div>
    
    <div className="Interview-Content__item">
      <iframe src="https://widget.spreaker.com/player?episode_id=16509653&theme=light&autoplay=false&playlist=false&cover_image_url=https%3A%2F%2Fd3wo5wojvuv7l.cloudfront.net%2Fimages.spreaker.com%2Foriginal%2F8132e778f74f04affdcfcc87e99bd6da.jpg" width="80%" height="50%" frameBorder="0" title="We are makers"></iframe>
    </div>

  </div>
</div>
      
  )
}

export default Interviews;